import $ from 'jquery';
import { setCookie, getCookie } from './cookies';
import { navigate } from 'gatsby';

// export const languageAPI = 'https://phase2-outrider.pantheonsite.io/api/get-language';
export const languageAPI = 'https://cms.outrider.org/api/get-language';
export const availableLanguages = [{ key: 'en', value: 'English' }, { key: 'es', value: 'Español' }];
// export const availableLanguages = [{ key: 'en', value: 'English' }];
const defaultLanguage = 'en';
let browserLang = '';
let translations = [];

export function setPageLanguage(lang) {
	if (typeof window !== 'undefined') {
		const urlParts = window.location.pathname.split('/');
		// let language = (availableLanguages.findIndex(availableLang => availableLang.key === lang) > -1) ? lang : defaultLanguage;
		let language = 'en';
		let hasLanguageInUrl = false;
		const hasRequesteLangInUrl = (urlParts.length > 1 && urlParts[1] === language);

		availableLanguages.forEach((availableLang) => {
			if (urlParts.indexOf(availableLang.key) > -1) {
				hasLanguageInUrl = true;
			}
		});

		if ((!hasLanguageInUrl && language === defaultLanguage) || hasRequesteLangInUrl) {
			return window.location.pathname;
		}

		// language is always the first part of our url. default language is english so it won't appear in url
		if (urlParts.length > 1) {
			if (language === defaultLanguage) {
				urlParts.splice(1, 1);
			} else if (urlParts.length === 2) {
				urlParts.splice(1, 0, language);
			} else if (urlParts[1] !== language) {
				if (hasRequesteLangInUrl) {
					urlParts[1] = language;
				} else {
					urlParts[0] = language;
				}
			}

			const url = urlParts.join('/');
			return (url.startsWith('/')) ? url : `/${url}`;
		} else {
			if (lang !== defaultLanguage) {
				// return `/${language}`;
				setCookie('language', 'en', 2000);
				return '/';
			} else {
				return '/';
			}
		}
	}

	return null;
}

/**
 * This function will look at the user's cookie, url, and browser language
 * to determine which language the page should be in and redirect if needed
 */
export function setPageLanguageUsingSettings() {
	// const cookieLang = getCookie('language');
	const cookieLang = 'en';
	const urlLang = getUrlLanguage();
	// const urlLang = 'en';
	let currentPageLanguage = (cookieLang) ? cookieLang : urlLang;

	if (urlLang) {
		if (typeof window !== 'undefined') {
			if (!isHomePage(window.location.pathname)) {
				// setCookie('language', urlLang, 2000);
				setCookie('language', 'en', 2000);
			} else {
				setCookie('language', 'en', 2000);
			} 
		}
		if (urlLang != currentPageLanguage) {
			if (typeof window !== 'undefined') {
				const path = setPageLanguage(urlLang);
				if (path && path !== window.location.pathname) {
					navigate(path);
				}
			}
		}
	} else if (cookieLang) {
		if (cookieLang != urlLang) {
			if (!(cookieLang === defaultLanguage && urlLang === null)) {
				if (typeof window !== 'undefined') {
					const path = setPageLanguage(cookieLang);
					if (path && path !== window.location.pathname) {
						navigate(path);
					}
				}
			}
		}
		currentPageLanguage = cookieLang;
	} else {
		const browserLang = getUserBrowserLanguage();
		if (browserLang != urlLang) {
			if (!(browserLang === defaultLanguage && urlLang === null)) {
				if (typeof window !== 'undefined') {
					const path = setPageLanguage(browserLang);
					if (path && path !== window.location.pathname) {
						navigate(path);
					}
				}
			}
		}
	}
}

export function setUserLanguage(lang) {
	// setCookie('language', lang, 2000);
	setCookie('language', 'en', 2000);
}

export async function getLanguage() {
	let urlLang = getUrlLanguage();
	let language = defaultLanguage;
	if (urlLang) {
		language = urlLang;
	} else {
		const cookieLang = getCookie('language');
		if (cookieLang) {
			language = cookieLang;
		} else {
			//get browswer language
			language = await getUserBrowserLanguage();
		}
	}
	return language;
}

export function getPageLanguage() {
	let urlLang = getUrlLanguage();
	if (urlLang) {
		return urlLang;
	}
	return defaultLanguage;
}

function isHomePage(path) {
    const homePaths = ["/", "/en", "/en/", "/es", "/es/"];
    if (homePaths.includes(path)) {
        return true;
    } else {
        return false;
    }
}

function getUrlLanguage() {
	if (typeof window !== 'undefined') {
		const urlParts = window.location.pathname.split('/');
		let urlLang = null;
		availableLanguages.forEach((availableLang) => {
			if (urlParts.indexOf(availableLang.key) > -1) {
				urlLang = availableLang.key;
			}
		});

		return urlLang;
	}
	return defaultLanguage;
}


async function getUserBrowserLanguage() { //
	if (!browserLang) {
		try {
			/* const results = await $.ajax({
				url: languageAPI,
				cache: false,
				async: false,
			}); */
			/*
			const results = await fetch(languageAPI);
			if (results.lang) {
				browserLang = results.lang;
			} else {
				browserLang = defaultLanguage;
			}
			*/
			browserLang = 'en';
			
			return browserLang;
		} catch (e) {
			browserLang = defaultLanguage;
			return browserLang;
		}
	}
	return browserLang;
}