import React, { Component } from 'react';
import * as style from './inlineMailChimpForm.module.scss';
import * as buttonStyle from '../ui/button.module.scss';
import $ from 'jquery';
import FormData from 'form-data';

const randNum = String(Math.floor(Math.random()*(999-100+1)+100));

class InlineMailChimpForm extends Component {
	constructor() {
		super();
		this.onSubmit = this.onSubmit.bind(this);
	};

	onSubmit = (e) => {
		e.preventDefault();
		
		const formData = new FormData(e.target);
		const data = {
			'EMAIL' : 	formData.get('EMAIL'),
			'FNAME' :	formData.get('FNAME'),
			'b_f638b14bd99d4a67f72a673b2_b758bb0b52': ''
		};
		
		$.ajax({
			url: 'https://outrider.us12.list-manage.com/subscribe/post-json?u=f638b14bd99d4a67f72a673b2&amp;id=b758bb0b52&c=?',
			dataType: 'jsonp',
			data: data,
			cache: false,
			success: function(data) {
				$('#mce-error-response').removeAttr('style').css('display', 'none');
				$('#mce-success-response').removeAttr('style').css('display', 'none');
				if(data.result && data.result === 'error') {
  				// console.log('ERROR 1');
					// $('#mce-error-response').html(data.msg);
					// $('#mce-error-response').css('display', 'block');
					
					/*
					$('.mce-success-response-'+randNum).css('display', 'none');
					$('.mce-success-response-'+randNum).hide();
					$('.mce-error-response-'+randNum).css('display', 'block');
					$('.mce-error-response-'+randNum).show();
					$('.mce-error-response-'+randNum).html(data.msg);
					*/
					
					//
					
					$('.magazine-form .mce-success-response').css('display', 'none');
					$('.magazine-form .mce-success-response').hide();
					$('.magazine-form .mce-error-response').css('display', 'block');
					$('.magazine-form .mce-error-response').show();
					$('.magazine-form .mce-error-response').html(data.msg);
				} else if(data.result && data.result === 'success') {
  				// console.log('SUCCESS 1');
					// $('#mailchimp-sign-up-form').removeAttr('style').css('display', 'none');
					// $('#mailchimp-thank-you-message').removeAttr('style');
					// $('#mce-error-response').css('display', 'none');
					// $('#mce-error-response').hide();
					
					/*
					$('.mce-error-response-'+randNum).css('display', 'none');
					$('.mce-error-response-'+randNum).hide();
					$('.mce-success-response-'+randNum).css('display', 'block');
					$('.mce-success-response-'+randNum).show();
					$('.mce-success-response-'+randNum).html('Thanks for your interest in our newsletter! An email is on its way. Please make sure to activate your subscription, by clicking on the confirmation link.');
					*/
					
					//
					
					$('.magazine-form .mce-error-response').css('display', 'none');
					$('.magazine-form .mce-error-response').hide();
					$('.magazine-form .mce-success-response').css('display', 'block');
					$('.magazine-form .mce-success-response').show();
					$('.magazine-form .mce-success-response').html('Thanks for your interest in our newsletter! An email is on its way. Please make sure to activate your subscription, by clicking on the confirmation link.');
					
					$('footer input').val('');
				} else {
  				// console.log('ERROR 2');
					// $('#mce-error-response').html('An unknown error has occurred');
					// $('#mce-error-response').css('display', 'block');
					
					/*
					$('.mce-success-response-'+randNum).css('display', 'none');
					$('.mce-success-response-'+randNum).hide();
					$('.mce-error-response-'+randNum).css('display', 'block');
					$('.mce-error-response-'+randNum).show();
					$('.mce-error-response-'+randNum).html('An unknown error has occurred');
					*/
					
					//
					
					$('.magazine-form .mce-success-response').css('display', 'none');
					$('.magazine-form .mce-success-response').hide();
					$('.magazine-form .mce-error-response').css('display', 'block');
					$('.magazine-form .mce-error-response').show();
					$('.magazine-form .mce-error-response').html('An unknown error has occurred');
				}
			}.bind(this),
			error: function(xhr, status, err) {
  			// console.log('ERROR 3');
				// $('#mce-error-response').html(err.toString());
				// $('#mce-error-response').css('display', 'block');
				
				/*
				$('.mce-success-response-'+randNum).css('display', 'none');
				$('.mce-success-response-'+randNum).hide();
				$('.mce-error-response-'+randNum).css('display', 'block');
				$('.mce-error-response-'+randNum).show();
				$('.mce-error-response-'+randNum).html(err.toString());
				*/
				
				//
				
				$('.magazine-form .mce-success-response').css('display', 'none');
				$('.magazine-form .mce-success-response').hide();
				$('.magazine-form .mce-error-response').css('display', 'block');
				$('.magazine-form .mce-error-response').show();
				$('.magazine-form .mce-error-response').html(err.toString());
			}.bind(this)
		});
	};

	render() {
		const {
			menu_social,
			showLogo,
			isFooter,
		} = this.props;
		const blogURL = (this.props.blogData) ? this.props.blogData.edges[0].node.path : '';
		
		let classes = `text-block page-block normal-text-block ${style.form}`;
		if (isFooter && isFooter === true) {
			classes = `${style.form} ${style.footerForm}`;
		}

		return (
			<div id="mc_embed_signup" className={classes}>
				<div id="mailchimp-sign-up-form" className="magazine-form">
					<form onSubmit={this.onSubmit} id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
						{showLogo &&
							<a href={blogURL}><div className={style.logo} /></a>
						}
						<div className={style.largeText}>Read our newsletter</div>
						<div className={style.summary}>We partner with creators, thought leaders, and news organizations to explain how smart policy can sustain a safe and livable planet. Please, join us.</div>
						<div className={`${style.inputGroup} mc-field-group`}>
							<div>
								<label htmlFor="mce-FNAME">Name</label>
								<input type="text" name="FNAME" className={`${style.emailInput} required email`} id="mce-FNAME" />
							</div>
							<div>
								<label htmlFor="mce-EMAIL">Email Address</label>
								<input type="email" name="EMAIL" className={`${style.emailInput} required email`} id="mce-EMAIL" />
							</div>
							<div className={style.spacer}>&nbsp;</div>
							<div>
								<button type="submit" name="subscribe" id="mc-embedded-subscribe" className={`${buttonStyle.button} ${buttonStyle.orangeButton}`}>
									<span className={buttonStyle.mainText}>Sign me up!</span>
								</button>
							</div>
							<div
								id="mce-responses"
								className={`${style.mceResponses} clear`}
							>	
								<div className={`${style.mceResponsesInset}`}>
									{/*
										style={{display: 'none'}}
									*/}
									<div
										className={`${style.errorResponse} mce-error-response mce-error-response-${randNum} response ${style.response}`}
										id="mce-error-response"
									>
									</div>
									<div
										className={`mce-success-response mce-success-response-${randNum} response ${style.response}`}
										id="mce-success-response"
									>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
				
				<div id="mailchimp-thank-you-message" style={{display: 'none'}}>
					<div className={style.smallGreyText}>Thank you</div>
					<div className={style.form.largeWhiteText}>Almost there!</div>
					<div className={style.textBlock} style={{width: '100%'}}>
						Thanks for your interest in our newsletter! An email is on its way. Please make sure to activate your subscription, by clicking on the confirmation link.
					</div>
				</div>
				
				{menu_social &&
					<ul className={style.navSocial}>
						{menu_social.map((item, i) => (
							<li key={`socialitem${i}`}>
								<a
									href={item.url}
									className={`icon-social icon-circle-white icon-medium ${item.class}`}
									target={item.target}
									rel="noopener"
								>
									<span>{item.title}</span>
								</a>
							</li>
						))}
					</ul>
				}
			</div>
		);
	};
};

export default InlineMailChimpForm;
