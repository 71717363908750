// extracted by mini-css-extract-plugin
export var button = "button-module--button--eYB0A";
export var morePadding = "button-module--morePadding--zMZ6H";
export var mainText = "button-module--mainText--L+g+Z";
export var noHover = "button-module--noHover--58v98";
export var icon = "button-module--icon--y6y4E";
export var hidden = "button-module--hidden--3Ud+2";
export var whiteButton = "button-module--whiteButton--Odzsg button-module--button--eYB0A";
export var blueButton = "button-module--blueButton--fjQs1 button-module--button--eYB0A";
export var blackButton = "button-module--blackButton--KHVV9 button-module--button--eYB0A";
export var greyButton = "button-module--greyButton--d82Kz button-module--button--eYB0A";
export var orangeButton = "button-module--orangeButton--M52Ez button-module--button--eYB0A";
export var whiteSolidButton = "button-module--whiteSolidButton--zUdbQ button-module--button--eYB0A";
export var whiteBorderButton = "button-module--whiteBorderButton--9xMtL button-module--button--eYB0A";
export var isBlock = "button-module--isBlock--o3b7l";
export var hasIcon = "button-module--hasIcon--o3xUL";
export var slideUp = "button-module--slideUp--ov2Uq";
export var slideUpLarge = "button-module--slideUpLarge--7A0hx";
export var buttonBorder = "button-module--buttonBorder--roAHm";