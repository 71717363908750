import * as style from './footer.module.scss';

import InlineMailChimpForm from '../../components/magazine/inlineMailChimpForm';
import Link from 'gatsby-link';
import React from 'react';
import { graphql } from 'gatsby';
import { navFooter } from '../global/primaryHeader/primaryHeader.module.scss';

const Footer = props => {
  const footer = (typeof props.edges === 'undefined') ?[] : props.edges[0].node.footer;
  const social = (typeof props.edges === 'undefined') ? [] : props.edges[0].node.menu_social;
  const navSocialItems = social.map((item, i) => {
    const useSVGClasses = ['icon-twitter'];

    const useSVG = item.class.some(c => useSVGClasses.includes(c));
    let classes = `icon-social white icon-no-pulse ${item.class}`;

    if (useSVG) {
      classes = item.class.join(' ') + ' white';
    }

    return (
      <li key={`socialitem${i}`} className={navFooter.navItem}>
        <a
          href={item.url}
          className={classes}
          target={item.target}
          rel="noopener"
        >
          {!useSVG && <span>{item.title}</span>}
          {useSVG && <span></span>}
        </a>
      </li>
    )
  });

  return (
    <footer className={`${style.footer} ${(props.isExtended) ? style.extended : ''}`}>
      <div className={`container-inner ${style.footerContent}`}>
        <div className={style.navFooter}>
          <div className={style.address}>
            <address>
              <div className={style.centeredMobile}>
                <div className={style.logo}></div>
              </div>
              834 E. Washington Ave., Suite
              333 Madison, WI 53703,&nbsp;
              <a href={`tel:+1 ${footer.phone_number}`}>
                {footer.phone_number}
              </a>
            </address>
          </div>
          {props.isExtended &&
            <div className={style.summary}>
              {footer.summary ? footer.summary : null}
            </div>
          }
          <div className={style.links}>
            {props.isExtended &&
              <div>
                <Link to={footer.about_link ? footer.about_link.url : null}>
                  {footer.about_link ? footer.about_link.title : null}{' '}
                </Link>
              </div>
            }
            <div>
              <Link to={footer.submissions_link ? footer.submissions_link.url : null}>
                {footer.submissions_link ? footer.submissions_link.title : null}{' '}
              </Link>
            </div>
            <div>
              <Link to={footer.link ? footer.link.url : null}>
               {footer.link ? footer.link.title : null}{' '}
              </Link>
            </div>
            <div>
              <Link to={footer.privacy_policy ? footer.privacy_policy.url : null}>
                {footer.privacy_policy ? footer.privacy_policy.title : null}{' '}
              </Link>
            </div>
          </div>
        </div>
        {navSocialItems && (
          <ul className={style.navSocial}>{navSocialItems}</ul>
        )}
      </div>
      {props.isExtended &&
        <InlineMailChimpForm isFooter={true} showLogo={false} blogData={props.blogData} />
      }
    </footer>
  );
};

export default Footer;

export const siteFooterQuery = graphql`
fragment footerFields on Query {
  footer: allItemsJson(filter: {jsonId: {eq: "en-11"}}) {
    edges {
      node {
        footer {
          address
          link {
            url
            title
          }
          about_link {
            url
            title
          }
          submissions_link {
            url
            title
          }
          privacy_policy {
            url
            title
          }
          disclaimer {
            url
            title
          }
          terms_of_use {
            url
            title
          }
          phone_number
          summary
        }
        menu_social {
          title
          menu_name
          weight
          url
          class
          target
        }
      }
    }
  }
}
fragment footerFieldsEs on Query {
  footerEs: allItemsJson(filter: {jsonId: {eq: "es-11"}}) {
    edges {
      node {
        footer {
          address
          about_link {
            url
            title
          }
          submissions_link {
            url
            title
          }
          link {
            url
            title
          }
          privacy_policy {
            url
            title
          }
          disclaimer {
            url
            title
          }
          terms_of_use {
            url
            title
          }
          phone_number
          summary
        }
        menu_social {
          title
          menu_name
          weight
          url
          class
          target
        }
      }
    }
  }
}
`;
