import React, { useEffect, useState } from 'react';
import * as style from './primaryHeader.module.scss';
import Link from 'gatsby-link';
import { getPageLanguage, availableLanguages } from '../../../utils/language';
import MegaMenu from '../../mega-menu';
import eventBus from '../../../utils/eventBus';
import LogoSvg from '../logoSvg';

const getCurrentSection = (activePath, headerChallenges) => {
  const trimmedPath = activePath.slice(0, -1);
  let currentSection = headerChallenges.edges.filter(challenge => {
    return challenge.node.path.indexOf(trimmedPath) !== -1;
  });
  if (trimmedPath.length <= 1) {
    currentSection = [];
  }
  return currentSection;
};

const PrimaryHeaderBar = props => {
  const currentSection = getCurrentSection(
    props.activePath,
    props.headerChallenges
  );
  const sectionLink =
    currentSection.length > 0 && currentSection[0].node.core_curriculum;

  let hideSectionLink =
    props.activePath.indexOf('interactive') !== -1 ||
    props.activePath.indexOf('impacts') !== -1;
  let isBlogArticle = false;
  if (props.pageData && props.pageData.articleType) {
    hideSectionLink = false;
    if (props.pageData.articleType === "Blog") {
      isBlogArticle = true;
    }
  }
  const blogURL = (props.blogData) ? props.blogData.edges[0].node.path : '';
  // const pageLanguage = getPageLanguage();
  const pageLanguage = 'en';
  // const toggleLanguage = availableLanguages.find(lang => lang.key != pageLanguage);
  const toggleLanguage = false;
  /*
  const LanguageSelector = () => (<button className={style.languageToggle} onClick={(e) => props.languageChange(toggleLanguage.key)}>
    <i className="fas fa-globe-americas" />
    <span>{toggleLanguage.value}</span>
  </button>);
  */
  const LanguageSelector = () => (<span></span>);

  const [explosion, setExplosion] = useState(false);

  useEffect(() => {
    const handleExplosion = () => {
      setExplosion(true);
    }

    eventBus.on('explosionComplete', handleExplosion);
    return () => eventBus.remove('explosionComplete', handleExplosion);
  }, []);

  return (
    <>
      <div
        className={`component-container ${props.headerShadow ? style.headerInnerShadowed : style.headerInner
          } ${style.primaryHeaderDesktop}`}
      >
        <div className={`${style.backgroundAnim} ${(props.megaMenuOpen || props.scrollY > 0) ? style.withBackground : ''} ${explosion ? style.withBackgroundBombBlast : ''}`}>
          <div className={style.headerElements}>
            <Link to="/" className={style.logoLink} onClick={props.removeMenuClick}>
            <LogoSvg/>
            </Link>
            <MegaMenu.Center open={props.megaMenuOpen} onToggleMegaMenu={props.onToggleMegaMenu} data={props.megaMenuData} />
            {/*
            <div className={style.headerGlobalElements}>
              {toggleLanguage &&
                LanguageSelector()
              }
            </div>
            */}
          </div>
        </div>
        <MegaMenu.Dropdown
          open={props.megaMenuOpen}
          onToggleMegaMenu={props.onToggleMegaMenu}
          data={props.megaMenuData} />
      </div>
      <MegaMenu.Mobile
        open={props.megaMenuOpen}
        onToggleMegaMenu={props.onToggleMegaMenu}
        languageSelector={LanguageSelector()}
        data={props.megaMenuData}
        menuSocial={props.menuSocial} />
    </>
  );
};

export default PrimaryHeaderBar;
