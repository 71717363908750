// extracted by mini-css-extract-plugin
export var wrapper = "mega-menu-module--wrapper--1Rsij";
export var megaMenu = "mega-menu-module--megaMenu--TR173";
export var center = "mega-menu-module--center--SKcYm";
export var my10 = "mega-menu-module--my10--3iPL9";
export var my20 = "mega-menu-module--my20--X1kdC";
export var link = "mega-menu-module--link--y24Ji";
export var dropdown = "mega-menu-module--dropdown--QzgzZ";
export var clearFloat = "mega-menu-module--clearFloat--b2HaG";
export var topics = "mega-menu-module--topics--K6-s+";
export var topicsMobile = "mega-menu-module--topicsMobile--0np1K";
export var educationalResources = "mega-menu-module--educationalResources--GPAZX";
export var educationalResourceItem = "mega-menu-module--educationalResourceItem--6uUol";
export var educationalResourcesMobile = "mega-menu-module--educationalResourcesMobile--kqLkq";
export var hidden = "mega-menu-module--hidden--8dKvS";
export var menuClose = "mega-menu-module--menuClose--X1sUp";
export var open = "mega-menu-module--open--OCPS+";
export var menuOpen = "mega-menu-module--menuOpen--d2i-z";
export var chevron = "mega-menu-module--chevron--z1odE";
export var chevronBlack = "mega-menu-module--chevronBlack--9yBCh";
export var chevronOrange = "mega-menu-module--chevronOrange--TME5k";
export var chevronOpen = "mega-menu-module--chevronOpen--Z-uzA";
export var header = "mega-menu-module--header--+nqcK";
export var centerContent = "mega-menu-module--centerContent--fqVp5";
export var loadingFiller = "mega-menu-module--loadingFiller--rhood";
export var loading = "mega-menu-module--loading--bOnd9";
export var fullWidth = "mega-menu-module--fullWidth--DSm6m";
export var angle = "mega-menu-module--angle--MtIdi";
export var megaMenuMobile = "mega-menu-module--megaMenuMobile---3qnv";
export var mobileBurgerContainer = "mega-menu-module--mobileBurgerContainer--SQ5H-";
export var mobileBurgerIcon = "mega-menu-module--mobileBurgerIcon--nZXr9";
export var mobileContent = "mega-menu-module--mobileContent--NQ4Kh";
export var mobileContentOpen = "mega-menu-module--mobileContentOpen--EMMI3";
export var mobileContentHeader = "mega-menu-module--mobileContentHeader--MPXgj";
export var mobileContentLogo = "mega-menu-module--mobileContentLogo--S+GGi";
export var mobileContentClose = "mega-menu-module--mobileContentClose--TniqP";
export var drawerOpen = "mega-menu-module--drawerOpen--cqYok";
export var drawerClosed = "mega-menu-module--drawerClosed--Ke2WL";
export var orangeText = "mega-menu-module--orangeText--5-SwA";
export var subMenu = "mega-menu-module--subMenu--iSh1O";
export var subMenuList = "mega-menu-module--subMenuList--x0XZz";
export var social = "mega-menu-module--social--+g7h9";
export var magazineLink = "mega-menu-module--magazineLink--Tm+Pr";
export var slideUp = "mega-menu-module--slideUp--0so6C";
export var slideUpLarge = "mega-menu-module--slideUpLarge--G4LbA";