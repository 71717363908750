import { Link } from 'gatsby';
import React from 'react';
import * as style from './mega-menu.module.scss';

export default function EducationResourceItem(props) {
	return <div className={props.className}>
		<Link to={props.data.uri} className={style.educationalResourceItem}>
			<img src={`${process.env.GATSBY_API_HOST}${props.data.image_url}`} alt={props.data.image_alt} />
			<div id="header" className={style.header}>{props.data.title}</div>
			<div id="explore">{props.label} <span className={style.angle}>&#8250;</span></div>
		</Link>
	</div>;
}