import React from 'react';

export default function LogoSvg() {
	return (
		<svg viewBox="0 0 148 17" fill="none" width="205" height="30">
			<path shapeRendering="geometricPrecision" d="M3 1.5C3 2.33333 2.33333 3 1.5 3C0.666667 3 0 2.33333 0 1.5C0 0.666667 0.666667 0 1.5 0C2.33333 0 3 0.666667 3 1.5Z" fill="white" />
			<path shapeRendering="geometricPrecision" fillRule="evenodd" clipRule="evenodd" d="M3 9.5C3 5.36957 6.36957 2 10.5 2C14.6304 2 18 5.36957 18 9.5C18 13.6304 14.6304 17 10.5 17C6.36957 17 3 13.6304 3 9.5ZM14.9928 9.46377C14.9928 7 13 4.97101 10.5 4.97101C8 4.97101 6.00725 7 6.00725 9.46377C6.00725 11.9275 8 13.9565 10.5 13.9565C12.9638 13.9565 14.9928 11.9275 14.9928 9.46377Z" fill="white" />
			<path shapeRendering="geometricPrecision" d="M24 10.4512V2H27.2869V10.378C27.2869 12.7927 28.5057 14.0366 30.5 14.0366C32.4943 14.0366 33.7131 12.8293 33.7131 10.4878V2H37V10.3415C37 14.8049 34.4517 17 30.4631 17C26.4744 17 24 14.8049 24 10.4512Z" fill="white" />
			<path shapeRendering="geometricPrecision" d="M48.406 5.04455H44V2H56V5.04455H51.594V17H48.406V5.04455Z" fill="white" />
			<path shapeRendering="geometricPrecision" fillRule="evenodd" clipRule="evenodd" d="M69.8714 2H63V17H66.3057V12.1985H68.8686H68.9057L72.1371 17H76L72.3229 11.6402C74.2543 10.933 75.5543 9.40695 75.5543 6.98759V6.95037C75.5543 5.53598 75.1086 4.38213 74.2543 3.52605C73.2514 2.52109 71.7657 2 69.8714 2ZM72.1743 7.1737C72.1743 8.43921 71.2457 9.29529 69.6486 9.29529H66.3057V4.97767H69.5743C71.1714 4.97767 72.1743 5.72208 72.1743 7.13648V7.1737Z" fill="white" />
			<path shapeRendering="geometricPrecision" d="M84 2H87V17H84V2Z" fill="white" />
			<path shapeRendering="geometricPrecision" fillRule="evenodd" clipRule="evenodd" d="M101.507 2H96V17H101.507C105.933 17 109 13.7327 109 9.5V9.46287C109 5.2302 105.933 2 101.507 2ZM105.724 9.53713C105.724 12.2104 104.016 14.0297 101.472 14.0297H99.067V4.9703H101.472C104.016 4.9703 105.724 6.82673 105.724 9.5V9.53713Z" fill="white" />
			<path shapeRendering="geometricPrecision" d="M116.036 2H126.929V4.93317H119.179V7.97772H126V10.9109H119.179V14.0668H127V17H116V2H116.036Z" fill="white" />
			<path shapeRendering="geometricPrecision" fillRule="evenodd" clipRule="evenodd" d="M141.889 2H135.037H135V17H138.296V12.1985H140.852H140.889L144.111 17H148L144.333 11.6402C146.259 10.933 147.556 9.40695 147.556 6.98759V6.95037C147.556 5.53598 147.111 4.38213 146.259 3.52605C145.259 2.52109 143.778 2 141.889 2ZM144.185 7.1737C144.185 8.43921 143.259 9.29529 141.667 9.29529H138.333V4.97767H141.593C143.185 4.97767 144.185 5.72208 144.185 7.13648V7.1737Z" fill="white" />
		</svg>
	)
}