import React from 'react';
import * as style from './mega-menu.module.scss';
import Link from 'gatsby-link';
import EducationResourceItem from './edu-res-component';
import { availableLanguages, getLanguage, getPageLanguage } from '../../utils/language';
import {
	logoLink as logoLinkStyle,
	languageToggle as languageToggleStyle
} from '../global/primaryHeader/primaryHeader.module.scss';
import Logo from '../global/logo';
import translations from '../../utils/translations';

class MegaMenu extends React.Component {
	static getUri(uri, lang) {
		return (lang === 'es' ? '/es' : '') + uri;
	}

	render() {
		return (
			<>{this.props.children}</>
		);
	}
}

class MegaMenuStandAlone extends React.Component {
	constructor(props) {
		super(props);
		this.onToggleMegaMenu = this.onToggleMegaMenu.bind(this);
	}

	state = {
		megaMenuOpen: false,
		toggleLanguage: availableLanguages.find(lang => lang.key !== getPageLanguage()),
	}

	onToggleMegaMenu() {
		this.setState(prev => ({ megaMenuOpen: !prev.megaMenuOpen }));
	}

	render() {
		return (
			<div className={style.wrapper}>
				<div className={style.megaMenu}>
					<Link to="/" className={logoLinkStyle}>
						<Logo />
					</Link>
					<MegaMenuCenter
						open={this.state.megaMenuOpen}
						onToggleMegaMenu={this.onToggleMegaMenu}
					/>
					{this.state.toggleLanguage &&
						<div>
							{/*
							<button className={languageToggleStyle} onClick={(e) => this.props.languageChange(this.state.toggleLanguage.key)}>
								<i className="fas fa-globe-americas" />
								<span>{this.state.toggleLanguage.value}</span>
							</button>
							*/}
						</div>
					}
				</div>
				<MegaMenuDropdown open={this.state.megaMenuOpen} onToggleMegaMenu={this.onToggleMegaMenu} />
			</div>
		);
	}
}

class MegaMenuCenter extends React.Component {
	constructor(props) {
		// console.log('props');
		// console.log(props);
		
		super(props);
	}
	state = {
		lang: 'en',
	};

	async componentDidMount() {
		const lang = await getLanguage();
		this.setState({ lang });
	}

	render() {
		// console.log('NODE');
		// console.log(this.props.data.edges.filter(f => f.node.lang === this.state.lang)[0].node);
	
		const {
			about,
			our_team,
			projects,
			newsletter,
			translated
		} = this.props.data.edges.filter(f => f.node.lang === this.state.lang)[0].node;

		return (
			<div className={style.center}>
				<ul>
					<li>
						<Link to={MegaMenu.getUri(projects.link, this.state.lang)}>{projects.title}</Link>
					</li>
					<li>
						<Link to={MegaMenu.getUri(our_team.link, this.state.lang)}>{our_team.title}</Link>
					</li>
					<li>
						<button type="button" onClick={this.props.onToggleMegaMenu}>
							{translated.stories_and_resources}&nbsp;
							<span className={`${style.chevron} ${this.props.open ? style.chevronOpen : ''}`} />
						</button>
					</li>
					<li>
						<Link to={MegaMenu.getUri(newsletter.link, this.state.lang)}>{newsletter.title}</Link>
					</li>
				</ul>
			</div>
		)
	}
}

class MegaMenuDropdown extends React.Component {
	constructor(props) {
		super(props);
		this.drawerRef = React.createRef();
		this.animationEnd = this.animationEnd.bind(this);
	}

	state = {
		lang: 'en',
	};

	animationEnd() {
		if (!this.props.open) {
			this.drawerRef.current.style.display = 'none';
		}
	}

	componentDidUpdate(prev) {
		if (prev.open !== this.props.open && this.props.open === true && this.drawerRef.current) {
			this.drawerRef.current.style.display = 'block';
		}
	}

	async componentDidMount() {
		const lang = await getLanguage();
		this.setState({ lang });
		this.drawerRef.current.addEventListener('animationend', this.animationEnd);
	}

	componentWillUnmount() {
		this.drawerRef.current.removeEventListener('animationend', this.animationEnd);
	}

	render() {

		const filtered = this.props.data.edges.filter(i => i.node.lang === this.state.lang);

		const {
			latest_stories,
			educational_resources,
			topics,
			magazine,
			translated
		} = filtered[0].node;

		const latestStories = latest_stories.map((v, index) => {
			return <li key={index}>
				<Link to={MegaMenu.getUri(v.uri, this.state.lang)}>{v.title}</Link>
			</li>;
		});

		const _topics = topics.map((v, index) => {
			return (
				<React.Fragment key={index}>
					<li>
						<Link to={v.link}>{v.display}</Link>
					</li>
				</React.Fragment>
			);
		});

		const educationalResources = educational_resources.map((e, index) => {
			const data = {...e};
			data.uri = MegaMenu.getUri(data.uri, this.state.lang);

			return <EducationResourceItem key={index} data={data} label={translations.t('Explore')} />;
		});

		return (
			<div
				ref={this.drawerRef}
				className={`${style.dropdown} ${this.props.open ? style.open : style.hidden}`}
				style={{ display: 'none' }}
			>
				<div className={style.centerContent}>
					<div>
						<span className={style.header}>{translations.t('Read the Latest')}</span>
						<ul>
							{latestStories}
						</ul>
					</div>
					<div className={style.topics}>
						<span className={style.header}>{translations.t('Topics')}</span>
						<ul>
							{_topics}
						</ul>
						<div className={style.clearFloat}></div>
						<span className={style.header}>{translations.t('Stories')}</span>
						<div>
							<Link className={style.magazineLink} to="/archive/climate-change">{translations.t('Climate Change')} <span className={style.angle}>&#8250;</span></Link>
							<Link className={style.magazineLink} to="/archive/nuclear-weapons">{translations.t('Nuclear Weapons')} <span className={style.angle}>&#8250;</span></Link>
						</div>
					</div>
					<div>
						<span className={style.header}>{translated.educational_resources}</span>
						<div className={style.educationalResources}>
							{educationalResources}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class MegaMenuMobile extends React.Component {
	chevronStyle = {
		width: 12,
		display: 'inline-block',
		marginLeft: 5
	};

	state = {
		dropdownOpen: false,
		latestOpen: false,
		topicsOpen: false,
		educationOpen: false,
		lang: 'en',
	};

	async componentDidMount() {
		const lang = await getLanguage();
		this.setState({ lang });
	}

	onToggleDropdown = () => {
		this.setState(prev => {
			return {
				dropdownOpen: !prev.dropdownOpen,
			};
		});
	};

	toggle = (key) => {
		this.setState(prev => {
			let update = {};
			update[key] = !prev[key];
			return update;
		});
	}

	render() {
		const filtered = this.props.data.edges.filter(i => i.node.lang === this.state.lang);

		const {
			about,
			our_team,
			projects,
			newsletter,
			latest_stories,
			educational_resources,
			topics,
			magazine,
			translated
		} = filtered[0].node;

		const latestStories = latest_stories.map((v, index) => {
			return <li key={index}>
				<Link to={MegaMenu.getUri(v.uri, this.state.lang)}>{v.title}</Link>
			</li>;
		});

		const _topics = topics.map((v, index) => {
			return (
				<li key={index}>
					<Link to={v.link}>{v.display}</Link>
				</li>
			);
		});

		const educationalResources = educational_resources.map((e, index) => {
			const data = {...e};
			data.uri = MegaMenu.getUri(data.uri, this.state.lang);

			return <EducationResourceItem className={style.my20} key={index} data={data} label={translations.t('Explore')} />;
		});

		const social = (typeof this.props.menuSocial === 'undefined') ? [] : this.props.menuSocial;
		const navSocialItems = social.map((item, i) => (
			<div key={`socialitem${i}`}>
				{/* className={`icon-social black icon-no-pulse ${item.class}`} */}
				<a
					href={item.url}
					className={`icon-social icon-no-pulse ${item.class}`}
					target={item.target}
					rel="noopener"
				>
					<span>{item.title}</span>
				</a>
			</div>
		));

		return (
			<div className={style.megaMenuMobile}>
				<button type="button" className={style.mobileBurgerContainer} onClick={this.props.onToggleMegaMenu}>
					<div className={style.mobileBurgerIcon}></div>
				</button>
				<div className={style.mobileLangSelector}>
					{this.props.languageSelector}
				</div>
				<div className={`${style.mobileContent} ${this.props.open ? style.mobileContentOpen : ''}`}>
					<div className={style.mobileContentHeader}>
						<Link to="/">
							<div className={style.mobileContentLogo}></div>
						</Link>
						<div className={style.mobileContentClose} onClick={this.props.onToggleMegaMenu}></div>
					</div>
					<ul>
						<li>
							<Link to={MegaMenu.getUri(projects.link, this.state.lang)}>{projects.title}</Link>
						</li>
						<li>
							<Link to={MegaMenu.getUri(our_team.link, this.state.lang)}>{our_team.title}</Link>
						</li>
						<li>
							<a onClick={this.onToggleDropdown}>
								{translated.stories_and_resources}
								<div style={this.chevronStyle} className={`${style.chevron} ${this.state.dropdownOpen ? style.chevronOpen : ''} ${style.chevronBlack}`}></div>
							</a>
							<div className={`${this.state.dropdownOpen ? style.drawerOpen : style.drawerClosed} ${style.subMenu}`}>
								<ul>
									<li>
										{/* LATEST STORIES */}
										<div onClick={() => this.toggle('latestOpen')}>
											<div className={style.orangeText}>
												{translations.t('Latest Stories')}
											</div>
											<div style={this.chevronStyle} className={`${style.chevron} ${this.state.latestOpen ? style.chevronOpen : ''} ${style.chevronOrange}`}></div>
										</div>
										<div className={`${this.state.latestOpen ? style.drawerOpen : style.drawerClosed}`}>
											<ul className={style.subMenuList}>
												{latestStories}
											</ul>
										</div>
									</li>
									<li>
										{/* TOPICS */}
										<div onClick={() => this.toggle('topicsOpen')}>
											<div className={style.orangeText}>
												{translations.t('Topics')}
											</div>
											<div style={this.chevronStyle} className={`${style.chevron} ${this.state.topicsOpen ? style.chevronOpen : ''} ${style.chevronOrange}`}></div>
										</div>
										<div className={`${this.state.topicsOpen ? style.drawerOpen : style.drawerClosed} ${style.topicsMobile}`}>
											<ul>
												{_topics}
											</ul>
											{/* MAGAZINE */}
											<div className={style.orangeText}>{translated.magazine}</div>
											<div className={style.my10}>
												<Link className={style.link} to={MegaMenu.getUri(magazine.link, this.state.lang)}>{translations.t('View All Stories')}</Link> <span className={style.angle}>&#8250;</span>
											</div>
										</div>
									</li>
									<li>
										{/* EDUCATIONAL RESOURCES */}
										<div onClick={() => this.toggle('educationOpen')}>
											<div className={style.orangeText}>
												{translated.educational_resources}
											</div>
											<div style={this.chevronStyle} className={`${style.chevron} ${this.state.educationOpen ? style.chevronOpen : ''} ${style.chevronOrange}`}></div>
										</div>
										<div className={` ${this.state.educationOpen ? style.drawerOpen : style.drawerClosed} ${style.educationalResourcesMobile}`}>
											{educationalResources}
										</div>
									</li>
								</ul>
							</div>
						</li>
						<li>
							<Link to={MegaMenu.getUri(newsletter.link, this.state.lang)}>{newsletter.title}</Link>
						</li>
						<li className={style.social}>
							<div style={{ fontSize: 16, display: 'block', marginBottom: 20 }}>{translations.t('Connect')}</div>
							{navSocialItems}
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

MegaMenu.Dropdown = MegaMenuDropdown;
MegaMenu.Center = MegaMenuCenter;
MegaMenu.StandAlone = MegaMenuStandAlone;
MegaMenu.Mobile = MegaMenuMobile;

export default MegaMenu;