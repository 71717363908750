// extracted by mini-css-extract-plugin
export var logo = "footer-module--logo--LXJLy";
export var centeredMobile = "footer-module--centeredMobile--eFnI8";
export var footer = "footer-module--footer--VR0pp";
export var footerContent = "footer-module--footerContent--BwwYw";
export var address = "footer-module--address--lNLLS";
export var navFooter = "footer-module--navFooter--xVkyI";
export var links = "footer-module--links--SFMo1";
export var navSocial = "footer-module--navSocial--i3pPE";
export var extended = "footer-module--extended--y8Koi";
export var summary = "footer-module--summary--oLQWl";
export var slideUp = "footer-module--slideUp--q1ZFY";
export var slideUpLarge = "footer-module--slideUpLarge--hbgse";