import * as style from './primaryHeader.module.scss';

import React, { Component } from 'react';
import { setPageLanguage, setUserLanguage } from '../../../utils/language';

import PrimaryHeaderBar from './primaryHeaderBar';
import animateScroll from '../../../utils/animateScroll';
import { graphql, navigate } from 'gatsby';

class PrimaryHeader extends Component {
  componentWillReceiveProps(nextProps) {
    if (this.props.isHidden && !nextProps.isHidden) {
      // make sure menu is scrolled to the top on open
      this.scrollNavUp();
    }
  }
  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }
  scrollNavUp = (animate, callback) => {
    if (animate) {
      let willFireCallback = false;
      if (this.scrollMenuInner.scrollTop !== 0) {
        willFireCallback = true;
        animateScroll(this.scrollMenuInner, callback);
      }
      if (this.menuScroll.scrollTop !== 0) {
        willFireCallback = true;
        animateScroll(this.menuScroll, callback);
      }
      if (!willFireCallback) {
        callback();
      }
    } else {
      this.scrollMenuInner.scrollTop = 0;
      this.menuScroll.scrollTop = 0;
    }
  };
  getScrollInnerRef = el => {
    this.scrollMenuInner = el;
  };
  getScrollRef = el => {
    this.menuScroll = el;
  };
  languageChange = lang => {
    setUserLanguage(lang);
    const url = setPageLanguage(lang);
    if (url) {
      navigate(url);
    }
  }

  onToggleMegaMenu() {
    this.setState(prev => ({megaMenuOpen: !prev.megaMenuOpen}));
  }

  render() {
    return (
      <header
        className={` ${style.primaryHeader} ${this.props.hide ? style.primaryHeaderHide : ''} 
        ${this.props.isHidden ? '' : style.menuActive} ${this.props.isIE ? style.isIE : ''}
        ${this.props.staticMenu ? style.primaryHeaderStatic : ''}
        `}
      >
        <PrimaryHeaderBar
          isHidden={this.props.isHidden}
          menuClick={this.props.menuClick}
          languageChange={this.languageChange}
          headerShadow={this.props.headerShadow}
          headerChallenges={this.props.headerChallenges}
          activePath={this.props.activePath}
          removeMenuClick={this.props.removeMenuClick}
          pageData={this.props.pageData}
          blogData={this.props.blogData}
          megaMenuOpen={this.props.megaMenuOpen}
          onToggleMegaMenu={this.props.onToggleMegaMenu}
          scrollY={this.props.scrollY}
          megaMenuData={this.props.megaMenuData}
          menuSocial={this.props.menuSocial}
        />
      </header>
    );
  }
}

export default PrimaryHeader;

export const siteHeaderQuery = graphql`
fragment headerFields on Query {
  header: allItemsJson(filter: {jsonId: {eq: "en-11"}}) {
    edges {
      node {
        id
        title
        subtitle
        short_description
        paragraphs {
          id
          title
          path
          type
          menu_items {
            label
            menu_item {
              id
              path
              title
              image {
                sizes {
                  square_sm
                  square_md
                  near_square_lg
                  near_square_md
                  original
                }
                alt_text
                title
              }
              archive {
                id
                title
                path
              }
              core_curriculum {
                id
                title
                path
              }
            }
          }
          link {
            url
            title
          }
          core_curriculum {
            id
            title
            path
          }
        }
        menu_footer {
          title
          menu_name
          weight
          url
        }
        menu_social {
          title
          menu_name
          weight
          url
          class
          target
        }
      }
    }
  }
}
fragment headerFieldsEs on Query {
  headerEs: allItemsJson(filter: {jsonId: {eq: "es-11"}}) {
    edges {
      node {
        id
        title
        subtitle
        short_description
        paragraphs {
          id
          title
          path
          type
          menu_items {
            label
            menu_item {
              id
              path
              title
              image {
                sizes {
                  square_sm
                  square_md
                  near_square_lg
                  near_square_md
                  original
                }
                alt_text
                title
              }
              archive {
                id
                title
                path
              }
              core_curriculum {
               id
               title
               path
              }
            }
          }
          link {
            url
            title
          }
          core_curriculum {
            id
            title
            path
          }
        }
        menu_footer {
          title
          menu_name
          weight
          url
        }
        menu_social {
          title
          menu_name
          weight
          url
          class
          target
        }
      }
    }
  }
}
`;
