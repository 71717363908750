class Translations {
	constructor() {
		this.lang = 'en';
		this.languageSources = [];
	}
	
	getLanguage() {
		return this.lang;
	}
	
	setLanguage(lang) {
		this.lang = lang;
	}
	
	setLanguageSource(lang, source) {
		if(source.edges) {
			this.languageSources[lang] = source.edges[0].node.translations;
		} else {
			this.languageSources[lang] = source;
		}
	}
	
	t(value) {
		let translatedValue = value
		if(this.languageSources[this.lang]) {
			this.languageSources[this.lang].forEach(translation => {
				if(translation.key === value) {
					translatedValue = translation.value;
					return;
				}
			});
		}
		
		return translatedValue;
	}
}
const translations = new Translations();
export default translations;