import React from 'react';
import Helmet from 'react-helmet';

const siteName = 'Outrider';

const getMetaTags = data => {
  const meta = [
    { property: 'og:site_name', content: siteName },
    { property: 'og:title', content: data.title },
    { name: 'twitter:title', content: data.title },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@OutriderFdn' },
    { property: 'og:type', content: data.isArticle ? 'article' : 'website' },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
  ];

  if (data.description) {
    meta.push({ name: 'twitter:description', content: data.description });
    meta.push({ property: 'og:description', content: data.description });
    meta.push({ name: 'description', content: data.description });
  }

  if (data.image) {
    meta.push({ name: 'image', property: 'og:image', content: data.image });
    meta.push({ property: 'og:image:url', content: data.image });
    meta.push({ property: 'og:image:secure_url', content: data.image });
    meta.push({ name: 'twitter:image', content: data.imageTwitter });
  }
  
  if(data.location) {
    meta.push({ property: 'og:url', content: `https://outrider.org${data.location.pathname}` });
  }
  
  if(data.preventIndexing) {
    meta.push({ name: 'robots', content: 'noindex' });
    meta.push({ name: 'robots', content: 'nofollow' });
    meta.push({ name: 'googlebot', content: 'noindex' });
    meta.push({ name: 'googlebot', content: 'nofollow' });
    meta.push({ name: 'googlebot-news', content: 'noindex' });
    meta.push({ name: 'googlebot-news', content: 'nofollow' });
    meta.push({ name: 'googlebot-news', content: 'nosnippet' });
  }
  
  return meta;
};

const getCanonicalUrl = props => {
  let pathname = (props.location !== undefined ) ? props.location.pathname : '';

  if(!pathname.endsWith('/')) {
    let pathnameAlt = pathname+'/';

    // return `https://outrider.org${pathnameAlt}`;
  }

  return `https://outrider.org${pathname}`;
};

const HeadTags = props => (
  <Helmet title={`${props.title} | ${siteName}`} meta={getMetaTags(props)}>
    <link rel="canonical" href={getCanonicalUrl(props)} />
  </Helmet>
);

export default HeadTags;
