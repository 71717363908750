import React from 'react';
import * as style from './logo.module.scss';

const Logo = props => {
  return (
    <div className={props.animated ? style.logoAnimated : style.logo}>
      <div className={style.logoContainer}>
        <span className={style.logoSatellite} />
        <span className={style.logoCircle} />
        {props.animated && <span className={style.logoArc} />}
      </div>
    </div>
  );
};

export default Logo;
